/**
 *
 * Project settings - override any values from platform settingsDefaults here
 *
 */

import settingsDefault from '_platform/src/theme/settingsDefault';

const settings = {
  // -- Base Settings --
  siteName: 'HP Commercial Club - Admin',
  siteLogo: '/hp-logo-@2x.png',
  siteLogoReversed: '/hp-logo-@2x.png',

  // -- Logo sizes --
  siteLoginLogoHeight: '100px',
  siteLoginLogoMaxHeight: '100px',
  siteLoginLogoWidth: 'auto',
  siteHeaderLogoHeight: '58px',
  siteHeaderLogoMaxHeight: '60px',
  siteHeaderLogoWidth: 'auto',

  // -- Cloudinary Settings --
  // Cloudinary cloud name
  cloudinaryCloudName: 'incremental',

  // Cloudinary folder - all lower case and no spaces. Eg: 'lori-test'
  // cloudinaryFolder: '', // no slashes, as it is also used for tags

  // Cloudinary upload preset
  // Configure in: https://cloudinary.com/console/settings/upload
  // Make sure to set:
  // 1. Preset name: prefix with the folder name, followed by the random string. eg. lori-test_e0wq62e8
  // 2. Signing Mode: Unsigned
  // 3. Folder: leave this blank
  // 4. Use filename: on (true)
  // 5. Unique filename: off (false)
  // cloudinaryUploadPreset: '',

  // Max image upload file size
  imageUploadFileSizeMax: 122880, // 120 KB
  imageUploadMaxFilesPerRequest: 5,

  // -- Default Overrides --

  // Important!: If the user is being redirected, ensure that the PrivateRoute
  // component has the `tempAuthAllowed` prop set, otherwise the user will not
  // be able to see/update their profile.
  // For further documentation, see _platform/src/theme/settingsDefault.js
  // redirectOnIncompleteProfile: '/profile/',

  // -- Admin options --
  // Data cache time (seconds) before allowing re-fetch
  dataCacheTime: 120,
  dataCacheTimeLong: 300,

  // Default page size for lists
  defaultPageSize: 100,

  // Users path - while it's defined in routes, it's not always easy to identify
  // which page to use as the path and page name can be changed.
  // This is used in modules outside of the User* scope
  usersPagePath: '/users',

  // Reward delivery methods
  deliveryMethods: [
    {
      value: 'Customer',
      label: 'Direct to customer',
    },
    {
      value: 'Client',
      label:
        'To Client (Distributor, Wholesaler, Member, Branch or Representative)',
    },
    {
      value: 'Email',
      label: 'Email',
    },
    {
      value: 'NoDeliveryOption',
      label: 'No delivery option',
    },
    {
      value: 'TBC',
      label: 'To be confirmed',
    },
  ],
  rewardTypes: [
    {
      value: 'Reward',
      label: 'Reward',
    },
    {
      value: 'BulkyReward',
      label: 'Bulky Reward',
    },
    {
      value: 'GiftCard',
      label: 'Gift Card',
    },
    {
      value: 'Charity',
      label: 'Charitable Donations',
    },
    {
      value: 'Money',
      label: 'Money - Emerchant VISA/Mastercards',
    },
    {
      value: 'Travel',
      label: 'Travel',
    },
    {
      value: 'DigitalGiftCard',
      label: 'Digital Gift Card',
    },
    {
      value: 'Subscription',
      label: 'Subscription - Future Implementation',
    },
  ],
  fulfilmentOrderRewardTypes: [
    {
      value: 'Reward',
      label: 'Reward',
    },
    {
      value: 'BulkyReward',
      label: 'Bulky Reward',
    },
    {
      value: 'GiftCard',
      label: 'Gift Card',
    },
  ],
};

// Note: If you are altering the breakpoints, make sure you loop over the
// values and create the media prop. See settingsDefault.js for implementation.

// Deep merge with default values
const settingsApp = { ...settingsDefault, ...settings };

export default settingsApp;
