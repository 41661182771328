import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { styled, css, up } from '@smooth-ui/core-sc';
import { Link } from 'react-router-dom';

import PrivateComponent from '_platform/src/utils/PrivateComponent';
import WelcomeSimple from '../WelcomeSimple/WelcomeSimple';
import Spotlight from 'components/Spotlight/Spotlight';

const HeaderWrapper = styled.header`
  align-items: center;
  //background-color: ${props => props.theme.customHeaderBackground};
  background-image: linear-gradient(to right, #0C1E33, #2B415A);
  color: ${props => props.theme.customHeaderColor};
  display: ${props => (props.verticalStack ? 'block' : 'flex')};
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
  ${props =>
    props.verticalStack ? 'text-align: center;' : ''} /* prettier-ignore-line */

  & > a {
    padding-bottom: 0.5em;
    padding-top: 0.5em;
    padding-left: 0.5em;
  }
  & > div {
    ${up(
      'md',
      css`
        padding: ${props => (props.verticalStack ? '0.25em 0 .5em' : '0 1em')};
      `
    )}
  }
`;

class Header extends Component {
  render() {
    return (
      <React.Fragment>
        <HeaderWrapper verticalStack={this.props.verticalStack}>
          <Link to="/">
            <img
              src={this.props.siteLogo || '/incremental-icon-192x192.png'}
              alt={this.props.siteName}
              style={{
                height: `${this.props.siteLogoHeight}`,
                maxHeight: `${this.props.siteLogoMaxHeight}`,
                width: `${this.props.siteLogoWidth}`,
              }}
            />
          </Link>

          <div>{this.props.siteName}</div>

          <PrivateComponent
            requiredRoles={['Administrator', 'DataAnalyst', 'Procurement']}
          >
            <Spotlight />
          </PrivateComponent>

          <WelcomeSimple onLogout={this.props.onLogout} />
        </HeaderWrapper>
        {this.props.children}
      </React.Fragment>
    );
  }
}

Header.propTypes = {
  verticalStack: PropTypes.bool,
  children: PropTypes.node,
  onLogout: PropTypes.func,
  siteLogo: PropTypes.string,
  siteLogoHeight: PropTypes.string,
  siteLogoMaxHeight: PropTypes.string,
  siteLogoWidth: PropTypes.string,
  siteName: PropTypes.string.isRequired,
};

Header.defaultProps = {
  verticalStack: undefined,
  children: undefined,
  onLogout: undefined,
  siteLogo: undefined,
  siteLogoHeight: '44px',
  siteLogoMaxHeight: '64px',
  siteLogoWidth: 'auto',
};

export default Header;
